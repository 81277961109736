import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function useUrlQueryState(
    key: string,
    defaultValue = '',
    options?: { clearOnUnmount?: boolean },
) {
    // url params
    const [params, setParams] = useSearchParams({});
    const navigate = useNavigate();

    const setValue = useCallback(
        (val: string, opts?: { replace?: boolean }) => {
            if (!val || val === defaultValue) {
                params.delete(key);
            } else {
                params.set(key, val);
            }
            setParams(params, opts);
        },
        [defaultValue, key, params, setParams],
    );

    useEffect(() => {
        return () => {
            if (options?.clearOnUnmount) {
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.delete(key);
                const url = (searchParams as any).size
                    ? `${window.location.pathname}?${searchParams}`
                    : window.location.pathname;

                navigate(url, { replace: true });
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currentValue = params.get(key) || defaultValue;
    return [currentValue, setValue] as const;
}
