import { Tooltip } from '@mui/material';
import React from 'react';

import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStatus, { WorkItemStatusDisplay } from 'features/workOrders/enums/WorkItemStatus';
import './WorkItemStatusProgress.scss';

// Progress bar showing a summary of manufacturer orders and their work Item statuses
export default function WorkItemStatusProgress({ data }: { data: Record<WorkItemStatus, number> }) {
    const totalCount = data.COMPLETED + data.INPROGRESS + data.ONHOLD + data.UNSTARTED;
    const percentCompleted = (data.COMPLETED / totalCount) * 100;
    const percentInProgress = (data.INPROGRESS / totalCount) * 100;
    const percentOnHold = (data.ONHOLD / totalCount) * 100;
    const percentUnstarted = (data.UNSTARTED / totalCount) * 100;

    return (
        <Tooltip
            title={
                <TooltipContent
                    data={data}
                    totalCount={totalCount}
                    percentCompleted={percentCompleted}
                />
            }
            placement="top"
            disableInteractive={true}
            arrow
        >
            <div className="WorkItemStatusProgress">
                <div className="WorkItemStatusProgress__Bar">
                    <div
                        className={`WorkItemStatusProgress__Bar__Progress ${WorkItemStatus.Completed}`}
                        style={{ width: `${percentCompleted}%` }}
                    />
                    <div
                        className={`WorkItemStatusProgress__Bar__Progress ${WorkItemStatus.InProgress}`}
                        style={{ width: `${percentInProgress}%` }}
                    />
                    <div
                        className={`WorkItemStatusProgress__Bar__Progress ${WorkItemStatus.OnHold}`}
                        style={{ width: `${percentOnHold}%` }}
                    />
                    <div
                        className={`WorkItemStatusProgress__Bar__Progress ${WorkItemStatus.Unstarted}`}
                        style={{ width: `${percentUnstarted}%` }}
                    />
                </div>
                <div className="WorkItemStatusProgress__Percent">
                    {percentCompleted.toFixed(0)}%
                </div>
            </div>
        </Tooltip>
    );
}

function TooltipContent({
    data,
    totalCount,
    percentCompleted,
}: {
    data: Record<WorkItemStatus, number>;
    totalCount: number;
    percentCompleted: number;
}) {
    return (
        <div className="WorkItemStatusProgress__Tooltip">
            <div className="WorkItemStatusProgress__Tooltip__Header">
                {totalCount} {totalCount === 1 ? 'item' : 'items'}, {percentCompleted.toFixed(0)}%
                Complete
            </div>
            <div className="WorkItemStatusProgress__Tooltip__Statuses">
                <TooltipWorkItemStatus
                    status={WorkItemStatus.Completed}
                    count={data.COMPLETED}
                />
                <TooltipWorkItemStatus
                    status={WorkItemStatus.InProgress}
                    count={data.INPROGRESS}
                />
                <TooltipWorkItemStatus
                    status={WorkItemStatus.OnHold}
                    count={data.ONHOLD}
                />
                <TooltipWorkItemStatus
                    status={WorkItemStatus.Unstarted}
                    count={data.UNSTARTED}
                />
            </div>
        </div>
    );
}

function TooltipWorkItemStatus({ status, count }: { status: WorkItemStatus; count: number }) {
    if (count === 0) {
        return null;
    }

    return (
        <div className="WorkItemStatusProgress__TooltipWorkItemStatus">
            <WorkItemStatusBadge
                className="WorkItemStatusProgress__TooltipWorkItemStatus__Icon"
                status={status}
                badgeType="icon"
            />
            <div className="WorkItemStatusProgress__TooltipWorkItemStatus__Label">
                {WorkItemStatusDisplay.display(status)}
            </div>
            <div className="WorkItemStatusProgress__TooltipWorkItemStatus__Value">{count}</div>
        </div>
    );
}
