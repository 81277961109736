import Icons from 'Icons';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import WorkItemStepHistoryModal from '../WorkItemStepHistoryModal/WorkItemStepHistoryModal';
import WorkItemStepStatusBadge from '../WorkItemStepStatusBadge/WorkItemStepStatusBadge';
import './WorkItemStepList.scss';

export default function WorkItemStepList({ steps }: { steps?: WorkItemStepBasic[] }) {
    const dialogManager = useDialogManager();

    const showHistory = useCallback(
        (step: WorkItemStepBasic) => {
            dialogManager.custom(WorkItemStepHistoryModal, {
                stepId: step.id,
                stepName: step.context.workflowStep.name,
                workItemTuid: step.context.workOrderItem.tuid,
                workItemDescription: step.context.workOrderItem.description,
            });
        },
        [dialogManager],
    );

    return (
        <div className="WorkItemStepList">
            {steps?.map(step => (
                <a
                    key={step.id}
                    className="WorkItemStepList__Step"
                    onClick={() => showHistory(step)}
                >
                    <div className="step-name">{step.context.workflowStep.name}</div>
                    <div className="workstation">{step.context.workstationLatest?.name}</div>
                    <WorkItemStepStatusBadge
                        model={step}
                        badgeType="badge"
                    />
                    <Icons.ChevronRight className="chevron" />
                </a>
            ))}
        </div>
    );
}
