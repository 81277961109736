import { rest } from 'msw';

import { faker } from '@faker-js/faker/locale/af_ZA';
import Env from 'config/Env';
import { fakeWorkItems } from 'features/workOrders/faker/WorkOrderFaker';
import {
    fakeSalesOrders,
    getFakeSalesItemLabels,
    getFakeSalesItemOptions,
} from './faker/SalesFaker';
import { SalesOrderWorkItem } from './models/SalesOrderWorkItem';

faker.seed(42);
const BASE_URL = `${Env.API_BASE_URL}`;

export const salesHandlers = [
    // Get work items by sales order id
    rest.get(`${BASE_URL}/salesorder/:salesOrderId/workorderitems`, (req, res, ctx) => {
        // look up order lines and generate a hash of line id to work item []
        const salesOrderId = parseInt(req.params.salesOrderId as string, 10);
        const salesOrder = fakeSalesOrders.find(s => s.legacyId === salesOrderId);
        const result = {} as Record<string, SalesOrderWorkItem[]>;

        salesOrder?.context.orderLines.forEach(line => {
            faker.seed(line.id);
            result[line.id] = getWorkItems(line.id);
        });

        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Get work items by sales order item id
    rest.get(`${BASE_URL}/salesorderitems/:itemId/workorderitems`, (req, res, ctx) => {
        const lineId = parseInt(req.params.itemId as string, 10);
        faker.seed(lineId);
        const result = getWorkItems(lineId);
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    rest.post(`${BASE_URL}/salesorder`, (req, res, ctx) => {
        const result = {
            data: fakeSalesOrders,
            total: fakeSalesOrders.length,
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    rest.get(`${BASE_URL}/salesorder/:salesOrderId`, (req, res, ctx) => {
        const result = fakeSalesOrders.find(s => s.id === req.params.salesOrderId);
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Labels
    rest.get(`${BASE_URL}/label-config/sales-order/:orderId`, (req, res, ctx) => {
        const result = { data: getFakeSalesItemLabels() };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    rest.get(`${BASE_URL}/old/manufacturer-order-lines/:id/inventory-options`, (req, res, ctx) => {
        const result = {
            data: getFakeSalesItemOptions(),
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
];

function getWorkItems(lineId: number): SalesOrderWorkItem[] {
    faker.seed(lineId);
    return faker.helpers.arrayElements(fakeWorkItems, {
        min: 0,
        max: 10,
    });
}

// function getWorkItems(): SalesOrderWorkItem[] {
//     // // group steps by work items
//     // const result = fakeWorkstationSteps.reduce((arr, step) => {
//     //     let item: SalesOrderWorkItem | undefined = arr.find(
//     //         i => i.id === step.context.workOrderItem.id,
//     //     );
//     //     if (!item) {
//     //         // create a new SalesOrderWorkItem now
//     //         item = {
//     //             id: step.context.workOrderItem.id,
//     //             tuid: step.context.workOrderItem.tuid,
//     //             context: {
//     //                 schedule: step.context.schedule,
//     //                 scheduleMeta: step.context.scheduleMeta,
//     //                 workOrder: step.context.workOrder,
//     //                 workOrderItemState: WorkItemStatus.Unstarted,
//     //                 workOrderItemSteps: [],
//     //             },
//     //         };
//     //         arr.push(item);
//     //     }
//     //     item.context.workOrderItemSteps?.push(step);
//     //     return arr;
//     // }, [] as SalesOrderWorkItem[]);

//     // // calculate status
//     // result.forEach(item => {
//     //     const lastStep =
//     //         item.context.workOrderItemSteps?.[item.context.workOrderItemSteps.length - 1];
//     //     if (
//     //         lastStep?.status === WorkItemStepStatus.Completed ||
//     //         lastStep?.status === WorkItemStepStatus.CompletedLocked
//     //     ) {
//     //         item.context.workOrderItemState = WorkItemStatus.Completed;
//     //     } else if (
//     //         item.context.workOrderItemSteps?.some(
//     //             (step: any) => step.status === WorkItemStepStatus.OnHold,
//     //         )
//     //     ) {
//     //         item.context.workOrderItemState = WorkItemStatus.OnHold;
//     //     } else if (
//     //         item.context.workOrderItemSteps?.some(
//     //             (step: any) =>
//     //                 step.status !== WorkItemStepStatus.Unstarted &&
//     //                 step.status !== WorkItemStepStatus.Skipped &&
//     //                 step.status !== WorkItemStepStatus.SkippedLocked,
//     //         )
//     //     ) {
//     //         item.context.workOrderItemState = WorkItemStatus.InProgress;
//     //     } else {
//     //         item.context.workOrderItemState = WorkItemStatus.Unstarted;
//     //     }
//     // });

//     // result.sort((a, b) => typescriptNaturalSort(a.tuid, b.tuid));

//     // return result;
// }
