import Icons from 'Icons';
import { WorkItemStepTransitionReasonDisplay } from 'features/workOrders/enums/WorkItemStepTransitionReason';
import WorkItemStepUiState, {
    WorkItemStepUiStateDisplay,
    computeWorkItemStepUiState,
} from 'features/workOrders/enums/WorkItemStepUiState';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import React, { useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkItemStepStatusBadge.scss';

export default function WorkItemStepStatusBadge({
    model,
    badgeType = 'badge',
    className,
}: {
    model: WorkItemStepBasic;
    badgeType?: 'badge' | 'icon' | 'title';
    className?: string;
}) {
    const uiState = useMemo(
        () => computeWorkItemStepUiState(model.status, model.context.canTransition),
        [model.context.canTransition, model.status],
    );

    return (
        <div
            className={coalesceClassNames(
                'WorkItemStepStatusBadge',
                `WorkItemStepStatusBadge--${badgeType}`,
                className,
                uiState,
            )}
        >
            {badgeType !== 'badge' && <StatusIcon uiState={uiState} />}
            {badgeType !== 'icon' && (
                <div className="WorkItemStepStatusBadge__Label">
                    {/* show the reason if we have it, otherwise show the current status */}
                    {model.lastTransitionReason
                        ? WorkItemStepTransitionReasonDisplay.display(model.lastTransitionReason)
                        : WorkItemStepUiStateDisplay.display(uiState)}
                </div>
            )}
        </div>
    );
}

function StatusIcon({ uiState }: { uiState: WorkItemStepUiState }) {
    return (
        <div className={coalesceClassNames('WorkItemStepStatusBadge__Icon', uiState)}>
            {uiState === WorkItemStepUiState.Completed ? (
                <Icons.StepComplete />
            ) : uiState === WorkItemStepUiState.InProgress ? (
                <Icons.StepStart />
            ) : uiState === WorkItemStepUiState.OnHold ? (
                <Icons.StepPause />
            ) : null}
        </div>
    );
}
