import MyModal from 'components/MyModal/MyModal';
import React, { useEffect, useRef } from 'react';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import './MyScrollableModal.scss';

export default function MyScrollableModal({
    close,
    children,
    className,
    isLoading = false,
    isError = false,
    fullHeight = true,
    mobileTitle,
    header,
    footer,
    containerSelector,
    withTabs = false,
    disableBackgroundClose = false,
}: {
    close?: () => void;
    children?: React.ReactFragment;
    className?: string;
    isLoading?: boolean;
    isError?: boolean;
    fullHeight?: boolean;
    mobileTitle: React.ReactFragment;
    header?: React.ReactFragment;
    footer?: React.ReactFragment;
    containerSelector?: string;
    withTabs?: boolean;
    disableBackgroundClose?: boolean;
}) {
    /** Keep track of component unmounting to prevent state update errors after the fact
     * this can happen during async save */
    const didUnmount = useRef(false);
    useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    return (
        <MyModal
            className={coalesceClassNames(
                'MyScrollableModal',
                withTabs && 'MyScrollableModal--with-tabs',
                className,
            )}
            close={close}
            disableBackgroundClose={disableBackgroundClose}
            fullHeight={fullHeight}
            isLoading={isLoading}
            isError={isError}
            containerSelector={containerSelector}
            mobileTitle={mobileTitle}
        >
            <div
                className={coalesceClassNames(
                    'MyScrollableModal__Header',
                    className && extendClassName(className, '__Header'),
                )}
            >
                {header}
            </div>
            <div
                className={coalesceClassNames(
                    'MyScrollableModal__Content',
                    className && extendClassName(className, '__Content'),
                )}
            >
                {children}
            </div>
            <div
                className={coalesceClassNames(
                    'MyScrollableModal__Footer',
                    className && extendClassName(className, `__Footer`),
                )}
            >
                {footer}
            </div>
        </MyModal>
    );
}
