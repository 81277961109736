import Icons from 'Icons';
import WorkItemStatus, { WorkItemStatusDisplay } from 'features/workOrders/enums/WorkItemStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkItemStatusBadge.scss';

export default function WorkItemStatusBadge({
    status,
    badgeType = 'badge',
    className,
}: {
    status: WorkItemStatus;
    badgeType?: 'badge' | 'icon' | 'title';
    className?: string;
}) {
    return (
        <div
            className={coalesceClassNames(
                'WorkItemStatusBadge',
                `WorkItemStatusBadge--${badgeType}`,
                className,
                status,
            )}
        >
            {badgeType !== 'badge' && <StatusIcon status={status} />}
            {badgeType !== 'icon' && (
                <div className="WorkItemStatusBadge__Label">
                    {WorkItemStatusDisplay.display(status)}
                </div>
            )}
        </div>
    );
}

function StatusIcon({ status }: { status: WorkItemStatus }) {
    return (
        <div className={coalesceClassNames('WorkItemStatusBadge__Icon', status)}>
            {status === WorkItemStatus.Completed ? (
                <Icons.StepComplete />
            ) : status === WorkItemStatus.InProgress ? (
                <Icons.StepStart />
            ) : status === WorkItemStatus.OnHold ? (
                <Icons.StepPause />
            ) : null}
        </div>
    );
}
