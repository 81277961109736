import Icons from 'Icons';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyDrawer from 'components/MyDrawer/MyDrawer';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import MyTabs from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import Env from 'config/Env';
import FeatureFlag from 'enums/FeatureFlag';
import SalesOrderCoreStatus from 'features/sales/enums/SalesOrderCoreStatus';
import { SalesItem } from 'features/sales/models/SalesItem';
import { SalesItemOptions } from 'features/sales/models/SalesItemOptions';
import { SalesOrderDetail } from 'features/sales/models/SalesOrderDetail';
import { SalesOrderWorkItem } from 'features/sales/models/SalesOrderWorkItem';
import salesApi from 'features/sales/sales.api';
import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import WorkItemStepList from 'features/workOrders/components/WorkItemStepList/WorkItemStepList';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import typescriptNaturalSort from 'typescript-natural-sort';
import { formatDateRelative } from 'utils/dateHelpers';
import { formatCurrency, isEmpty } from 'utils/helpers';
import { PostHogFeature, useFeatureFlagEnabled } from 'utils/posthogHelpers';
import LabelPrintDialog, { LabelPrintDialogResult } from '../LabelPrintDialog/LabelPrintDialog';
import SalesItemEditModal from '../SalesItemEditModal/SalesItemEditModal';
import SalesItemStatusBadge from '../SalesItemStatusBadge/SalesItemStatusBadge';
import SalesOrderWorksheetsDialog from '../SalesOrderWorksheetsDialog/SalesOrderWorksheetsDialog';
import './SalesItemDrawer.scss';
import { SalesItemDrawerHistoryTab } from './SalesItemDrawerHistoryTab';

export default function SalesItemDrawer({
    model,
    order,
    isLoading = false,
    isError = false,
    close,
}: {
    model?: SalesItem;
    order?: SalesOrderDetail;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    // cant use tab as a key because its already used by the salesOrderDetailModal
    const [tab, setTab] = useUrlQueryState('itemTab', 'details', { clearOnUnmount: true });

    /** Close the drawer whenever user clicks out of the drawer
     * Excluding when they click on another row in the line items table within SalesOrderDetailModal
     */
    useEffect(() => {
        function handleClick(e: MouseEvent) {
            const tableElem = document.querySelector('.SalesOrderDetailModal__ItemsTable');
            const salesOrderModalElem = document.querySelector('.SalesOrderDetailModal');
            const target = e.target as Element;

            // close the drawer when clicking outside the drawer (i.e anywhere on the parent modal)
            // except for the line items table
            if (salesOrderModalElem?.contains(target) && !tableElem?.contains(target)) {
                close?.();
            }
        }

        // Bind the event listener
        document.addEventListener('click', handleClick);

        // cleanup when unmounting
        return () => document.removeEventListener('click', handleClick);
    }, [close]);

    const dialogManager = useDialogManager();
    const useNewLabelsRoutes = useFeatureFlagEnabled(FeatureFlag.GenerateLabelsNew);

    const [labelConfigQuery] = salesApi.useLazySalesItemLabelConfigQuery();
    const [generateLabelMutationOld] = salesApi.useSalesItemGenerateLabelsOldMutation();
    const [generateLabelMutationNew] = salesApi.useSalesOrderGenerateLabelsNewMutation();
    const [salesItemWriteLogMutation] = salesApi.useSalesItemWriteLogMutation();
    const [generateWorksheetsMutation] = salesApi.useSalesItemGenerateWorksheetsMutation();

    /** Print summary - write to log and download pdf */
    const printSummary = useCallback(async () => {
        if (model) {
            await dialogManager.showLoadingWhile(
                salesItemWriteLogMutation({
                    id: model.id,
                    itemNumber: model.lineNumber,
                    action: 'line_number',
                    download: true,
                    downloadType: 'sales order',
                }),
            );
            window.open(
                `${Env.API_BASE_URL}/old/manufacturer-order-lines/generate-sales-order/${model?.id}`,
                '_blank',
            );
        }
    }, [dialogManager, model, salesItemWriteLogMutation]);

    /** Print label - write to log and download pdf */
    const printLabels = useCallback(async () => {
        if (model && order) {
            try {
                // get label configs
                const config = await dialogManager.showLoadingWhile(
                    labelConfigQuery(model.id).unwrap(),
                );

                const labelPrintResult: LabelPrintDialogResult = await dialogManager.custom(
                    LabelPrintDialog,
                    {
                        config,
                    },
                );

                if (!labelPrintResult.confirmed) {
                    // user cancelled
                    return;
                }

                // write to log
                await dialogManager.showLoadingWhile(
                    salesItemWriteLogMutation({
                        id: model.id,
                        itemNumber: model.lineNumber,
                        action: 'line_number',
                        download: true,
                        downloadType: 'product label',
                    }),
                );

                // generate label
                const documentUrl = await dialogManager.showLoadingWhile(
                    useNewLabelsRoutes
                        ? generateLabelMutationNew({
                              order,
                              item: model,
                              labelIds: labelPrintResult.labelIds,
                              copies: labelPrintResult.copies,
                          }).unwrap()
                        : generateLabelMutationOld({
                              order,
                              line: model,
                              labelIds: labelPrintResult.labelIds,
                              copies: labelPrintResult.copies,
                          }).unwrap(),
                );
                window.open(documentUrl, '_blank');
            } catch (e) {
                dialogManager.alert({
                    title: 'Failed to generate label',
                    message: 'Please check your label configs before trying again.',
                });
            }
        }
    }, [
        model,
        order,
        dialogManager,
        labelConfigQuery,
        salesItemWriteLogMutation,
        useNewLabelsRoutes,
        generateLabelMutationNew,
        generateLabelMutationOld,
    ]);

    /** Print worksheets - write to log and show a list of xls to download */
    const printWorksheets = useCallback(async () => {
        if (model) {
            // write to log
            const promise = salesItemWriteLogMutation({
                id: model.id,
                itemNumber: model.lineNumber,
                action: 'line_number',
                download: true,
                downloadType: 'worksheet',
            })
                .unwrap()
                .then(() => generateWorksheetsMutation(model).unwrap());

            const result = await dialogManager.showLoadingWhile(promise);

            // show dialog
            await dialogManager.custom(SalesOrderWorksheetsDialog, {
                data: result,
                titleContext: `Sales Item ${model.lineNumber}`,
            });
        }
    }, [dialogManager, generateWorksheetsMutation, model, salesItemWriteLogMutation]);

    const edit = useCallback(() => {
        dialogManager.custom(SalesItemEditModal, {
            model,
        });
    }, [dialogManager, model]);

    return (
        <MyDrawer
            className="SalesItemDrawer"
            onClose={close}
            anchor="right"
            hideBackdrop={true}
            isLoading={isLoading}
            isError={isError}
            header={
                <>
                    <MyButton
                        className="SalesItemDrawer__Header__BackButton"
                        IconRight={Icons.ChevronLeft}
                        buttonType="None"
                        onClick={close}
                    />
                    <PageHeader
                        className="SalesItemDrawer__PageHeader"
                        title="Sales Item"
                        titleContext={`${model?.lineNumber}`}
                    >
                        <MyButton
                            buttonType="Accent"
                            IconLeft={Icons.Edit}
                            label="Edit"
                            onClick={edit}
                        />
                        <MyMenuKebabButton
                            menuItems={useMemo(
                                (): MyMenuButtonItem[] => [
                                    {
                                        label: 'Print summary',
                                        IconLeft: Icons.SalesOrderSummary,
                                        onClick: printSummary,
                                    },
                                    {
                                        label: 'Print labels',
                                        IconLeft: Icons.SalesOrderLabel,
                                        onClick: printLabels,
                                    },
                                    {
                                        label: 'Print worksheets',
                                        IconLeft: Icons.SalesOrderWorksheet,
                                        onClick: printWorksheets,
                                    },
                                ],
                                [printLabels, printSummary, printWorksheets],
                            )}
                        />
                    </PageHeader>
                </>
            }
        >
            {model && (
                <>
                    {order?.context.status.coreStatus === SalesOrderCoreStatus.Cancelled && (
                        <MessagePanel
                            className="SalesItemDrawer__CancelledMessage"
                            messageType="warning"
                        >
                            This order has been cancelled
                        </MessagePanel>
                    )}
                    <MyTabs
                        className="SalesItemDrawer__Tabs"
                        activeTab={tab}
                        setActiveTab={setTab}
                        tabs={[
                            {
                                name: 'details',
                                label: 'Details',
                                content: <DetailsTab model={model} />,
                            },
                            {
                                name: 'workItems',
                                label: 'Work Items',
                                content: (
                                    <WorkItemsTab
                                        key={model.id}
                                        model={model}
                                    />
                                ),
                            },
                            {
                                name: 'history',
                                label: 'History',
                                content: (
                                    <SalesItemDrawerHistoryTab
                                        key={model.id}
                                        model={model}
                                    />
                                ),
                            },
                        ]}
                    />
                </>
            )}
        </MyDrawer>
    );
}

function DetailsTab({ model }: { model: SalesItem }) {
    const optionsQuery = salesApi.useSalesItemOptionsQuery({
        itemId: model.id,
        manufacturerOrderId: model.manufacturerOrderId,
    });
    const dialogManager = useDialogManager();

    const showAdvancedOptions = useCallback(() => {
        dialogManager.custom(AdvancedOptionsModal, {
            model,
            salesItemOptions: optionsQuery.data,
        });
    }, [dialogManager, model, optionsQuery.data]);

    return (
        <div className="SalesItemDrawer__DetailsTab">
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Description"
                    value={model.context.product.name}
                    verticalAlign="top"
                />

                <PropertyDisplay
                    label="Quantity"
                    value={model.quantity}
                />

                <PropertyDisplay
                    label="Status"
                    value={<SalesItemStatusBadge status={model.context.status} />}
                />

                {(model.context.orderWindow.notes || model.context.product.notes) && (
                    <div className="PropertyContainerSpacer" />
                )}

                {model.context.orderWindow.notes && (
                    <PropertyDisplay
                        label="Window Notes"
                        value={model.context.orderWindow.notes}
                        verticalAlign="top"
                    />
                )}

                {model.context.product.notes && (
                    <PropertyDisplay
                        label="Product Notes"
                        value={model.context.product.notes}
                        verticalAlign="top"
                    />
                )}

                <PropertyDisplay
                    label="Notes"
                    value={model.notes}
                    verticalAlign="top"
                />
            </PropertyContainer>

            <table className="SalesItemDrawer__PricingDisplay">
                <thead>
                    <tr>
                        <th>Pricing</th>
                        <th>Unit</th>
                        <th>Line</th>
                    </tr>
                </thead>
                <tbody>
                    <PostHogFeature
                        flag={FeatureFlag.CostPrice}
                        match={true}
                    >
                        <tr>
                            <th>Cost price</th>
                            <td>{formatCurrency(model.unitCostPrice)}</td>
                            <td>{formatCurrency(model.lineCostPrice)}</td>
                        </tr>
                    </PostHogFeature>
                    <tr>
                        <th>Sell price</th>
                        <td>{formatCurrency(model.unitSellPrice)}</td>
                        <td>{formatCurrency(model.lineSellPrice)}</td>
                    </tr>
                    <tr>
                        <th>Tax</th>
                        <td>{formatCurrency(model.unitTax)}</td>
                        <td>{formatCurrency(model.lineTax)}</td>
                    </tr>
                </tbody>
            </table>

            <h3 className="SalesItemDrawer__SectionHeader">Measurements</h3>
            {optionsQuery.isFetching ? (
                <div className="SalesItemDrawer__DetailsTab__MeasurementsLoading">
                    <MyLinearProgress />
                </div>
            ) : (
                <PropertyContainer layout="table">
                    <PropertyDisplay
                        label="Width"
                        value={optionsQuery.data?.measurements?.width}
                    />
                    <PropertyDisplay
                        label="Height"
                        value={optionsQuery.data?.measurements?.height}
                    />
                </PropertyContainer>
            )}

            <h3 className="SalesItemDrawer__SectionHeader">Options</h3>
            {optionsQuery.isFetching ? (
                <div className="SalesItemDrawer__DetailsTab__OptionsLoading">
                    <MyLinearProgress />
                </div>
            ) : (
                <>
                    {(optionsQuery.data?.options?.length ?? 0) > 0 ? (
                        <InfoGridTable>
                            {optionsQuery.data?.options?.map((opt, index) => (
                                <InfoGridRow
                                    key={index}
                                    label={opt.name ?? ''}
                                    value={opt.value}
                                />
                            ))}
                        </InfoGridTable>
                    ) : (
                        <div className="SalesItemDrawer__SectionEmpty">&ndash;</div>
                    )}
                    <div className="SalesItemDrawer__AdvancedOptionsLink">
                        <MyButton
                            className="Link"
                            buttonType="LinkButton"
                            isLoading={optionsQuery.isFetching}
                            onClick={showAdvancedOptions}
                            label="Show advanced"
                        />
                    </div>
                </>
            )}
        </div>
    );
}

function AdvancedOptionsModal({
    model,
    salesItemOptions,
    close,
}: {
    model: SalesItem;
    salesItemOptions?: SalesItemOptions;
    close?: () => void;
}) {
    const optionsList = useMemo(() => {
        const labels = salesItemOptions?.labels ?? [];
        const synthesizedOptions = salesItemOptions?.synthesized_options ?? [];
        const options = [...labels, ...synthesizedOptions];

        options.sort((a, b) => typescriptNaturalSort(a.name, b.name));

        return options;
    }, [salesItemOptions?.labels, salesItemOptions?.synthesized_options]);

    return (
        <MyModal
            className="SalesItemDrawer__AdvancedOptionsModal"
            close={close}
            containerSelector="#modal-root-top"
            mobileTitle="Sales Item"
            header={
                <PageHeader
                    className="SalesItemDrawer__AdvancedOptionsModal"
                    title="Advanced Options"
                    titleContext={`Sales Item ${model?.lineNumber}`}
                ></PageHeader>
            }
        >
            {isEmpty(optionsList) && isEmpty(salesItemOptions?.secondary_product_options) ? (
                // Empty state
                <div className="SalesItemDrawer__SectionEmpty">No advanced options found</div>
            ) : (
                <>
                    {!isEmpty(optionsList) && (
                        <InfoGridTable>
                            {optionsList?.map((label, index) => (
                                <InfoGridRow
                                    key={index}
                                    label={label.name}
                                    value={label.value}
                                />
                            ))}
                        </InfoGridTable>
                    )}

                    {!isEmpty(salesItemOptions?.secondary_product_options) && (
                        <div className="SalesItemDrawer__AdvancedOptionsModal__SecondaryProduct">
                            <h3 className="SalesItemDrawer__AdvancedOptionsModal__SecondaryProduct__Title">
                                Secondary Product
                            </h3>
                            <InfoGridTable>
                                {salesItemOptions?.secondary_product_options?.map(
                                    (label, index) => (
                                        <InfoGridRow
                                            key={index}
                                            label={label.name}
                                            value={label.value}
                                        />
                                    ),
                                )}
                            </InfoGridTable>
                        </div>
                    )}
                </>
            )}
        </MyModal>
    );
}

function WorkItemsTab({ model }: { model: SalesItem }) {
    const query = salesApi.useSalesItemWorkItemsQuery({
        itemId: model.id,
        manufacturerOrderId: model.manufacturerOrderId,
    });

    return (
        <div className="SalesItemDrawer__WorkItemsTab">
            {query.isLoading ? (
                <div className="SalesItemDrawer__WorkItemsTab__Loading">
                    <MyLinearProgress />
                </div>
            ) : query.isError ? (
                <ErrorContent className="SalesItemDrawer__WorkItemsTab__Error" />
            ) : isEmpty(query.data) ? (
                <div className="SalesItemDrawer__WorkItemsTab__SectionEmpty">
                    No work items found
                </div>
            ) : (
                query.data?.map(item => (
                    <WorkItemBlock
                        key={item.id}
                        item={item}
                    />
                ))
            )}
        </div>
    );
}

function WorkItemBlock({ item }: { item: SalesOrderWorkItem }) {
    const workOrder = item.context.workOrder;
    const schedule = item.context.schedule;
    return (
        <div className="SalesItemDrawer__WorkItemBlock">
            <div className="SalesItemDrawer__WorkItemBlock__Header">
                <div className="SalesItemDrawer__WorkItemBlock__Header__Title">
                    <WorkItemStatusBadge
                        badgeType="title"
                        status={item.context.workOrderItemState}
                    />
                    <h2 className="tuid">| {item.tuid}</h2>
                </div>
                <div className="SalesItemDrawer__WorkItemBlock__Header__Date">
                    <Link
                        className="Link"
                        to={`/schedule?date=${schedule?.date}&highlight=${workOrder?.id}`}
                    >
                        <Icons.Schedule />
                        {formatDateRelative(schedule?.date)}
                    </Link>
                </div>
            </div>
            <WorkItemStepList steps={item.context.workOrderItemSteps} />
        </div>
    );
}
