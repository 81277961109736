import z from 'zod';
import WorkItemFieldUnits from '../enums/WorkItemFieldUnits';
import { WorkOrderItemEntitySchema } from './WorkOrderItemEntity';

export const WorkstationWorkItemFieldSchema = z.object({
    key: z.string(),
    value: z.union([z.string(), z.number(), z.boolean()]).nullable(),
    displayName: z.string().nullable(),
    hideIfEmpty: z.boolean(),
    unitSystem: z.nativeEnum(WorkItemFieldUnits),
});

export const WorkstationWorkItemSchema = WorkOrderItemEntitySchema.extend({
    identifier: z.string().nullable(),
    detailFields: z.array(WorkstationWorkItemFieldSchema),
    summaryFields: z.array(WorkstationWorkItemFieldSchema),
});

export type WorkstationWorkItemField = z.infer<typeof WorkstationWorkItemFieldSchema>;
export type WorkstationWorkItem = z.infer<typeof WorkstationWorkItemSchema>;
